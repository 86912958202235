import React, { Component } from 'react'
import { Link } from 'gatsby'
import he from 'he'

import Tag from '../tag'

class Tile extends Component {

  _getImage() {
    let { acf } = this.props
    let featured_image = acf && acf.featured_image
    if (featured_image && featured_image.localFile) return featured_image.localFile.childImageSharp.fluid.src
    return featured_image && featured_image.url
  }

  render() {
    let link = this.props.slug ? this.props.slug : this.props.post_name


    let featured_image = this._getImage()
    let title = this.props.title ? this.props.title : this.props.post_title

    let { excerpt, content_blocks } = this.props.acf
    if (content_blocks && content_blocks.length) {
      for (let i = 0; i < content_blocks.length; i++) {
        excerpt = content_blocks[i].content
        if (typeof excerpt === 'string') {
          excerpt = excerpt.replace(/(<([^>]+)>)/ig, "").substring(0, 300)
          break
        }
      }
    }

    return (
      <Link to={ `/article/${link}` } className='archive__tile'>
        <div className='archive__tile__image'>
          { featured_image && <picture style={{ backgroundImage: `url(${ featured_image })` }} /> }
          <Tag category={this.props.category} />
        </div>
        <div className='archive__tile__text'>
          <div className='archive__tile__text__inner'>
            <h2>{ he.decode(title) }</h2>
            { excerpt && <div className='archive__tile__paragraph' dangerouslySetInnerHTML={{ __html: excerpt }} /> }
          </div>
        </div>
      </Link>
    )
  }
}

export default Tile
