import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import logo from '../assets/images/small_logo_inverse.svg'

class BrandBanner extends Component {
  render() {
    let { data } = this.props
    const ROWS = data.allWordpressAcfOptions.edges[0].node.options.page_footer
    const row = ROWS[Math.floor(Math.random()*ROWS.length)]
    return (
      <div className='brand-banner' style={{ backgroundImage: `url(${row.image.url})`}}>
        <img src={logo} alt='the father hood logo' />
        <p dangerouslySetInnerHTML={{__html: row.text }} />
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressAcfOptions {
          edges {
            node {
              options {
                page_footer {
                  image {
                    url
                    alt
                  }
                  text
                }
              }
            }
          }
        }
      }
    `}
    render={data => <BrandBanner data={data} {...props} />}
  />
)
