import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import he from 'he'

import Tile from '../components/archive/tile'
import TextItem from '../components/archive/text-item'
import SocialBar from '../components/social-bar'
import BrandBanner from '../components/brand-banner'

const social = {
  title: 'Add me to the Dad list',
  className: 'full'
}

class AuthorPage extends Component {
  render() {
    const author = this.props.data.wordpressWpAuthor
    let profile_picture = author.acf.profile_picture

    let articles = this.props.data.allWordpressPost.edges.filter((el, i) => {
      let authors = el.node.acf.author || []
      for(let i = 0, l = authors.length; i < l; i++) {
        if (authors[i].wordpress_id === author.wordpress_id) {
          return true
        }
      }
      return false
    })

    let collection = articles.slice(0, 8)
    let textItems = articles.slice(8)

    return (
      <>
        <Helmet>
          <title>{ he.decode(author.yoast_meta.yoast_wpseo_title) }</title>
          <meta name="description" content={author.yoast_meta.yoast_wpseo_metadesc} />
          <meta property="og:title" content={ he.decode(author.yoast_meta.yoast_wpseo_title) } />
          <meta property="og:description" content={ author.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="twitter:title" content={ he.decode(author.yoast_meta.yoast_wpseo_title) } />
          <meta property="twitter:description" content={ author.yoast_meta.yoast_wpseo_metadesc } />
          <meta property="og:type" content="profile" />
          { profile_picture && [
            <meta property="og:image" content={profile_picture.url} key={0} />,
            <meta property="og:image:width" content={profile_picture.width} key={1} />,
            <meta property="og:image:height" content={profile_picture.height} key={2} />,
            <meta property="og:image:url" content={profile_picture.url} key={3} />,
          ]}
        </Helmet>
        <article className="article article--author">
          <div className='article__header'>
            <div className='article__header__image' style={{ backgroundImage: `url(${profile_picture && profile_picture.url})` }} />
            <div className='article__header__title'>
              <div className='article__header__title__inner'>
                <h1>{author.title}</h1>
                <span className="article__header__tagline">{author.acf.tagline}</span>
                <div className='article__header__author__bio' dangerouslySetInnerHTML={{__html: author.content }} />
              </div>
            </div>
          </div>
        </article>
        <div className='archive__collection'>
          { collection.map((el, i) => <Tile {...el.node} key={i} />) }
        </div>
        <div className='archive__text-item__container'>
          { textItems.length > 0 &&
            <div className='archive__text-item__inner'>
              { textItems.map((el, i) => <TextItem {...el.node} key={i} />) }
            </div>
          }
        </div>
        <SocialBar {...social} />
        <BrandBanner />
      </>
    )
  }
}

export default AuthorPage

export const authorQuery = graphql`
  query currentAuthorQuery($id: String!, $wordpress_id: Int!) {
    wordpressWpAuthor(id: { eq: $id }) {
      wordpress_id
      slug
      title
      content
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        profile_picture {
          alt
          url
        }
        tagline
      }
    }

    allWordpressPost(filter: {acf: {author:{elemMatch:{wordpress_id: {eq: $wordpress_id}}}}}, limit: 16) {
      edges {
        node {
          title
          slug
          acf {
            author {
              wordpress_id
            }
            excerpt
            featured_image {
              alt
              url
              width
              height
            }
          }
        }
      }
    }
  }
`


