import React, { Component } from 'react'

class Tag extends Component {
  render() {
    return (
      <>
        { this.props.category && <div className={`tag tag--${this.props.category.toLowerCase()}`}>{ this.props.category }</div> }
      </>
    )
  }
}

export default Tag
